import { FC, PropsWithChildren } from 'react';
import {
  FirebaseAppProvider as ReactfireFirebaseAppProvider,
  SuspenseWithPerf, useFirebaseApp, useInitPerformance,
} from 'reactfire';

import SSRFallback from '@/c/utils/SSRFallback';

const firebaseConfig = JSON.parse(
  JSON.parse(`"${process.env.firebaseConfig}"`),
);

const FirebasePerformanceProvider = () => {
  const firebaseApp = useFirebaseApp();

  useInitPerformance(
    async () => {
      const { getPerformance } = await import('firebase/performance');
      return getPerformance(firebaseApp);
    },
    { suspense: false },
  );

  return null;
};

const FirebaseAppProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <SSRFallback
    fallback={(
      <ReactfireFirebaseAppProvider firebaseConfig={firebaseConfig} suspense={false}>
        {children}
      </ReactfireFirebaseAppProvider>
    )}
  >
    <ReactfireFirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
      <FirebasePerformanceProvider />
      {/* TODO: Add loading animation */}
      <SuspenseWithPerf fallback="root-loading..." traceId="firebase-root-suspense">
        {children}
      </SuspenseWithPerf>
    </ReactfireFirebaseAppProvider>
  </SSRFallback>
);

export default FirebaseAppProvider;
