import {
  FC, PropsWithChildren, ReactNode, useEffect, useState,
} from 'react';

/**
 * Helper wrapper to support two-pass rendering.
 * Suppress hydration warnings from SSR.
 * @link https://reactjs.org/docs/react-dom.html#:~:text=If%20you%20intentionally%20need%20to%20render%20something%20different%20on%20the%20server%20and%20the%20client%2C%20you%20can%20do%20a%20two%2Dpass%20rendering.
 * @param fallback - Component to render on server.
 * @param children - Component to render on client.
 */
const SSRFallback: FC<PropsWithChildren<{ fallback: ReactNode }>> = ({ children, fallback }) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(typeof window !== 'undefined'), []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{isClient ? children : fallback}</>
  );
};

export default SSRFallback;
