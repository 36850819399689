import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookie } from 'react-use';
import { PrismicToolbar } from '@prismicio/react';
import * as prismic from '@prismicio/client';

import DisableSSR from '@/c/utils/DisableSSR';

const usePreviewCookieRef = () => {
  const [rawPreviewCookie] = useCookie(prismic.cookie.preview);

  const previewCookie = rawPreviewCookie ? JSON.parse(rawPreviewCookie) : undefined;
  const previewCookieObject = previewCookie?.[`${process.env.prismicRepoName}.prismic.io`];
  const previewCookieRef = previewCookieObject?.preview;

  return (typeof previewCookieRef === 'string') ? previewCookieRef : undefined;
};

// reference: https://prismic.io/docs/technologies/previews-nextjs#3.1.-useupdatepreviewref.js-hook
export const useUpdatePreviewRef = (previewRef, documentId) => {
  const router = useRouter();
  const previewCookieRef = usePreviewCookieRef();

  const enterPreviewRoute = `/api/preview?token=${previewCookieRef}&documentId=${documentId}`;
  const enterPreview = () => window.location.assign(enterPreviewRoute);

  useEffect(() => {
    // Skip if no preview cookie
    if (!previewCookieRef) return;

    // Preview cookie expired, re-enter preview mode
    if (router.isPreview && (previewCookieRef !== previewRef)) enterPreview();

    // Preview cookie exists, but not in preview mode, enter preview mode
    if (!router.isPreview && previewCookieRef) enterPreview();
  });
};

const PrismicExitPreviewButtonContent = () => {
  const router = useRouter();
  const exitPreviewRoute = `/api/exit-preview?currentUrl=${router.asPath}`;
  const exitPreview = () => window.location.assign(exitPreviewRoute);

  const previewCookieRef = usePreviewCookieRef();

  return (
    (router.isPreview && !previewCookieRef)
      ? (
        <div className="fixed bottom-0 right-0 z-50">
          <div className="p-3">
            <Button onClick={exitPreview} variant="contained" color="warning">離開預覽模式</Button>
          </div>
        </div>
      )
      : null
  );
};

export const PrismicExitPreviewButton = () => (
  <DisableSSR>
    <PrismicExitPreviewButtonContent />
  </DisableSSR>
);

export const PrismicPreviewProvider = () => (
  <>
    {/* Prismic script loader */}
    <PrismicToolbar repositoryName={process.env.prismicRepoName} />
    <PrismicExitPreviewButton />
  </>
);
