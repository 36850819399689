import { FC, PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

const DisableSSR: FC<PropsWithChildren<{}>> = ({ children }) => (
  <>
    {/* Pass children */}
    {children}
  </>
);

export default dynamic(() => Promise.resolve(DisableSSR), { ssr: false });
