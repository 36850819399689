import { useAnalytics } from 'reactfire';
import { logEvent, setUserId } from 'firebase/analytics';

export const useEventLogger = () => {
  const analytics = useAnalytics();

  return (eventName: string, eventParameters?: any) => {
    logEvent(analytics, eventName, eventParameters);
  };
};

export const useUserIdSetter = () => {
  const analytics = useAnalytics();

  return (userId: string) => {
    setUserId(analytics, userId);
  };
};
