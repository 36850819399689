import Head from 'next/head';

const GoogleFonts = () => (
  <Head>
    <link key="preconnect-fonts.googleapis.com" rel="preconnect" href="https://fonts.googleapis.com" />
    <link key="preconnect-fonts.gstatic.com" rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    {/* eslint-disable-next-line @next/next/no-page-custom-font */}
    <link key="google-fonts-noto-sans-tc" href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet" />
  </Head>
);

export default GoogleFonts;
