// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/tailwind.css';

import { RecoilRoot } from 'recoil';
import type { AppProps } from 'next/app';

import GoogleFonts from '@/c/common/GoogleFonts';
import FloatingCTA from '@/c/common/FloatingCTA';
import MicrosoftClarity from '@/c/common/MicrosoftClarity';
import ReactQueryProvider from '@/c/providers/ReactQueryProvider';
import FirebaseAppProvider from '@/c/providers/FirebaseAppProvider';
import FirebaseAnalyticsProvider from '@/c/providers/FirebaseAnalyticsProvider';
import { PrismicPreviewProvider } from '@/utils/prismic/preview';

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <GoogleFonts />
    <MicrosoftClarity />
    <RecoilRoot>
      <ReactQueryProvider>
        <FirebaseAppProvider>
          <FirebaseAnalyticsProvider>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...pageProps} />
            {/* TODO: move FloatingCTA into PageLayout */}
            <FloatingCTA />
          </FirebaseAnalyticsProvider>
        </FirebaseAppProvider>
      </ReactQueryProvider>
    </RecoilRoot>
    <PrismicPreviewProvider />
  </>
);

export default MyApp;
