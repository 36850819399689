import {
  FC, PropsWithChildren, useCallback, useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { getAnalytics } from 'firebase/analytics';
import { useFirebaseApp, AnalyticsProvider } from 'reactfire';

import SSRFallback from '@/c/utils/SSRFallback';
import { useEventLogger } from '@/c/hooks/FirebaseAnalyticsHooks';

const FirebaseAnalyticsPageviewProvider: FC = () => {
  const router = useRouter();
  const logEvent = useEventLogger();

  const handleRouteChanges = useCallback(() => {
    logEvent('page_view', { page_path: router.asPath });
  }, [logEvent, router.asPath]);

  // Send page view event on initial render
  useEffect(() => handleRouteChanges());

  useEffect(() => {
    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChanges);

    // Unsubscribe route changes when the component unmounts
    return () => router.events.off('routeChangeComplete', handleRouteChanges);
  }, [router.events, handleRouteChanges]);

  return null;
};

const FirebaseAnalyticsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const firebaseApp = useFirebaseApp();

  return (
    <AnalyticsProvider sdk={getAnalytics(firebaseApp)}>
      {children}
      {/* Only log page views on client */}
      <FirebaseAnalyticsPageviewProvider />
    </AnalyticsProvider>
  );
};

const FirebaseAnalyticsProviderWithSSRFallback: FC<PropsWithChildren<{}>> = ({ children }) => (
  <SSRFallback fallback={children}>
    <FirebaseAnalyticsProvider>{children}</FirebaseAnalyticsProvider>
  </SSRFallback>
);

export default FirebaseAnalyticsProviderWithSSRFallback;
