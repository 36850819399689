import Link from 'next/link';
import BoltIcon from '@mui/icons-material/Bolt';
import { useRouter } from 'next/router';

const FloatingCTA = () => {
  const { asPath } = useRouter();

  // Hide floating CTA on the /inquiry page
  if (asPath === '/inquiry') return null;

  return (
    <div className="container mx-auto sticky bottom-0 w-full z-40">
      <Link href="/inquiry" passHref>
        <a className="block pb-0-safe bg-zinc-700 rounded-t-lg text-white overflow-hidden">
          <div className="flex items-center justify-center py-1.5">
            <BoltIcon className="!text-2xl" />
            <span className="text-lg">立即預約諮詢</span>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default FloatingCTA;
