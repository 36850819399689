import { ReactQueryDevtools } from 'react-query/devtools';
import { FC, PropsWithChildren, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import SSRFallback from '@/c/utils/SSRFallback';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: typeof window !== 'undefined',
    },
  },
});

const ReactQueryProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <SSRFallback fallback={children}>
      <Suspense fallback="loading...">
        {children}
      </Suspense>
    </SSRFallback>
  </QueryClientProvider>
);

export default ReactQueryProvider;
